import { PolymerElement } from '@polymer/polymer/polymer-element.js';
class DbqDropdown extends PolymerElement {
  static get is() {
    return 'dbq-dropdown';
  }

  static get properties() {
    return {
      active: {
        type: Boolean,
        reflectToAttribute: true
      }
    };
  }

  constructor() {
    super();
  }

  ready() {
    super.ready();
    setTimeout(() => {
      this.addEventListener('click', (event) => {
        let classList = event.target.classList;
        let tagName = event.target.tagName;
        // console.log("🚀 ~ file: dbq-dropdown.js ~ line 26 ~ DbqDropdown ~ this.addEventListener ~ tagName", tagName)
        if (
          classList.contains('dropMenu') ||
          classList.contains('dropItem') ||
          tagName === 'DBQ-DROPDOWN'
        ) {
          return;
        }
        this.toggle();
      });
    });
    document.body.addEventListener('click', (event) => {
      let classList = event.target.classList;
      let tagName = event.target.tagName;
      if (
        classList.contains('dropTitle') ||
        classList.contains('dropMenu') ||
        classList.contains('dropItem') ||
        classList.contains('icon') ||
        classList.contains('icon-text') ||
        classList.contains('icon-white') ||
        tagName === 'SPAN' ||
        tagName === 'DIV' ||
        tagName === 'I'
      ) {
        return;
      }
      this.active = false;
    });
  }

  toggle() {
    if (!this.active) {
      document.querySelectorAll('dbq-dropdown[active]').forEach((el) => {
        el.active = false;
      });
    }
    this.active = !this.active;
  }

  close(){
    this.active = false;
  }
}
customElements.define(DbqDropdown.is, DbqDropdown);
