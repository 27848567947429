// import './dbq-modal.js'
import { GlobalBase } from './global-base.js'
import { html } from '@polymer/polymer/polymer-element.js';
import { modal } from 'uikit';
class DbqLangModal extends GlobalBase {
  static get is() {
    return 'dbq-lang-modal';
  }
  static get properties() {
    return {
      htmlFromApi: String,
    }
  }

  constructor() {
    super();
  }

  ready() {
    super.ready();
    this.addEventListener('click', this.getHtmlFromApi);
    console.log('lang modal ready');
  }

  getHtmlFromApi(event){
    event.preventDefault();
    if(event.target.tagName == "DBQ-LANG-MODAL"){
      return;
    }
    console.log('event', event.target.parentNode.tagName);
    let url = event.target.href;
    if(event.target.tagName !== "A"){
      url = event.target.parentNode.href;
    }
    //console.log("Hi : : DbqLangModal -> getHtmlFromApi -> event", event)
    console.log('url', url);
    this.getEndpointasHTML(url).then((response) => {
      this.htmlFromApi = response.replace(/onclick="/gi, 'onclick="event.stopPropagation();return;');
      let content = `<div class="uk-modal-header">
            <h5 class="uk-margin-remove-top uk-margin-remove-bottom">Page preview</h5>
            <button class="uk-modal-close-default" type="button" uk-close></button>
        </div>
        <div class="uk-modal-body">${this.htmlFromApi}</div>`
      modal.dialog(content).$el.querySelector('.uk-modal-dialog').classList.add('xl-modal-dialog');
    })
  }

  _attachDom(dom) {
    this.appendChild(dom);
  }
}
customElements.define(DbqLangModal.is, DbqLangModal);
