
if ( typeof(availableTranslations) !== 'undefined' && window.polyglot === undefined) {
  // console.log("Hi : : GlobalBase -> initPolyGlot -> availableTranslations", typeof availableTranslations)

  window.polyglot = new Polyglot({phrases: availableTranslations});
}
import './dynamic/sw-base.js';
//https://medium.com/front-end-weekly/webpack-and-dynamic-imports-doing-it-right-72549ff49234
//only load scripts that are needed in the different sections or an assignment

function loadDynamicImports() {

  if (
    window.location.pathname.includes('instructor/courses')
  ) {
    import(
      /* webpackChunkName: "inst"*/
      './dynamic/dbq-inst-manage-app.js'
    );
  return;
  }
  if (
    window.location.pathname.includes('instructor/assignment/create/course') ||
    window.location.pathname.includes('instructor/assignment/edit') ||
    window.location.pathname.includes('template/create') ||
    window.location.pathname.includes('template/edit')
  ) {
    import(
      /* webpackChunkName: "inst-asgn"*/
      './dynamic/dbq-inst-asgn.js'
    );
    return;
  }


  if (
    !window.location.pathname.includes('rubric') &&
    !window.location.pathname.includes('comment') &&
    !window.location.pathname.includes('dashboard')
  ) {
    import(
    /* webpackChunkName: "anno" */
    './dynamic/sw-anno.js'
    );
  }

  if (window.location.pathname.includes('comment')) {
    import(
      /* webpackChunkName: "comments" */
      './dynamic/sw-essay-feedback.js'
    );
  }

  if (window.location.pathname.includes('rubric')) {
    import(
      /* webpackChunkName: "rubr" */
      './dynamic/sw-rubric.js'
    );
  }

  if (window.location.pathname.includes('/essay/')) {
    import(
      /* webpackChunkName: "edit" */
      '../components/sw-text-editor.js');
  }

  if (window.location.pathname.includes('essay_builder')) {
    import(
      /* webpackChunkName: "buil" */
      './dynamic/sw-essaybuilder.js'
    );
  }


  if (window.location.pathname.includes('guided-essay')) {
    import(
      /* webpackChunkName: "guid" */
      '../components/dbq-guided-essay.js'
    );
  }

  //daSheets
  if (
    window.location.pathname.includes('teachers-toolkit') ||
    window.location.pathname.includes('/eo/') ||
    window.location.pathname.includes('/essay_builder/') ||
    window.location.pathname.includes('/essay/') ||
    window.location.pathname.includes('/eb/') ||
    window.location.pathname.includes('/assignment/')
  ) {
    import(
      /* webpackChunkName: "shee" */
      './dynamic/sw-da-sheets.js'
    );
  }

  // district admin rubric creation
  // if (
  //   window.location.pathname.includes('/district/rubric/create/') ||
  //   window.location.pathname.includes('/district/rubric/update/')
  // ) {
  //   import(
  //     /* webpackChunkName: "da-rubrics" */
  //     '../components/dbq-da-rubric-creation.js'
  //   );
  // }
}

loadDynamicImports();
