import './dbq-modal.js';
import { html } from '@polymer/polymer/polymer-element.js';
import { GlobalBase } from './global-base.js';

/**
 * this is the session timeout modal
 * it also listens for a custom event called 'response' that will restart the timer
 * example: window.dispatchEvent(new CustomEvent('response', { bubbles: true, composed: true, detail: { succeeded: true } }));
 *
 **/

class SwBlockingError extends GlobalBase {
  static get is() {
    return 'sw-blocking-error';
  }

  static get template() {
    return html`
      <svg
        version="1.1"
        style="display:none;"
        xmlns="http://www.w3.org/2000/svg">
        <symbol viewBox="0 0 47.4 40" id="time-error">
          <title>clock</title>
          <polyline
            points="0.7 22 4.7 18 8.7 22"
            fill="none"
            stroke="#515151"
            stroke-miterlimit="10"
            stroke-width="2"/>
          <path
            d="M42.2,26.8a19,19,0,0,1-37-6v-2"
            transform="translate(-0.4 -0.8)"
            fill="none"
            stroke="#515151"
            stroke-miterlimit="10"
            stroke-width="2"/>
          <polyline
            points="46.7 18 42.7 22 38.7 18"
            fill="none"
            stroke="#515151"
            stroke-miterlimit="10"
            stroke-width="2"/>
          <path
            d="M43.1,22.8v-2a19,19,0,0,0-37-6"
            transform="translate(-0.4 -0.8)"
            fill="none"
            stroke="#515151"
            stroke-miterlimit="10"
            stroke-width="2"/>
          <polyline
            points="27.7 25 23.7 21 23.7 12"
            fill="none"
            stroke="#515151"
            stroke-miterlimit="10"
            stroke-width="2"/>
          <circle
            cx="23.7"
            cy="20"
            r="12"
            fill="none"
            stroke="#515151"
            stroke-miterlimit="10"
            stroke-width="2"/>
        </symbol>

        <symbol viewBox="0 0 411.9 246.9" id="cloud-error">
          <style type="text/css">
            .st0 {
              fill-rule: evenodd;
              clip-rule: evenodd;
              fill: #35c6f4;
            }
            .st1 {
              fill-rule: evenodd;
              clip-rule: evenodd;
              fill: #34679a;
            }
          </style>
          <path
            class="st0"
            d="M336,94.6c40.9,0,74,33.1,74,74c0,40.9-33.1,74-74,74H79.3C37.2,242.6,3,208.4,3,166.3c0-39.6,30.1-72.1,68.7-75.9c4-48.7,44.8-86.9,94.5-86.9c35.6,0,66.6,19.6,82.9,48.7c8.3-5.3,18.1-8.4,28.7-8.4c28.7,0,52,22.7,53,51.1C332.5,94.7,334.3,94.6,336,94.6z"/>
          <g>
            <path class="st1"
              d="M152.1,201c-3,3.4-8.2,3.7-11.6,0.6c-3.4-3-3.7-8.2-0.6-11.6c7.1-7.9,16.9-14.2,28.4-18.4c11.2-4.2,24-6.5,37.3-6.5c13.2,0,26,2.3,37.3,6.5c11.5,4.3,21.4,10.6,28.4,18.4c3,3.4,2.7,8.5-0.6,11.6c-3.4,3-8.5,2.7-11.6-0.6c-5.2-5.8-12.9-10.6-21.9-14c-9.3-3.5-20.2-5.4-31.5-5.4c-11.4,0-22.2,1.9-31.5,5.4C165,190.3,157.3,195.1,152.1,201z"/>
            <path class="st1"
              d="M134.1,107.4c-3.2-3.2-3.2-8.4,0-11.6c3.2-3.2,8.4-3.2,11.6,0l36.1,36.1c3.2,3.2,3.2,8.4,0,11.6c-3.2,3.2-8.4,3.2-11.6,0L134.1,107.4z"/>
            <path class="st1"
              d="M170.2,95.8c3.2-3.2,8.4-3.2,11.6,0c3.2,3.2,3.2,8.4,0,11.6l-36.1,36.1c-3.2,3.2-8.4,3.2-11.6,0c-3.2-3.2-3.2-8.4,0-11.6L170.2,95.8z"/>
            <path class="st1"
              d="M231.2,107.4c-3.2-3.2-3.2-8.4,0-11.6c3.2-3.2,8.4-3.2,11.6,0l36.1,36.1c3.2,3.2,3.2,8.4,0,11.6c-3.2,3.2-8.4,3.2-11.6,0L231.2,107.4z"/>
            <path class="st1"
              d="M267.3,95.8c3.2-3.2,8.4-3.2,11.6,0c3.2,3.2,3.2,8.4,0,11.6l-36.1,36.1c-3.2,3.2-8.4,3.2-11.6,0c-3.2-3.2-3.2-8.4,0-11.6L267.3,95.8z"/>
          </g>
        </symbol>
      </svg>

      <iron-ajax
        bubbles
        id="poll"
        url="[[url]]"
        method="GET"
        on-response="handleResponse"
        on-error="handleErrorResponse">
      </iron-ajax>

      <dbq-modal id="sessionEnded" hidden>
        <svg><use href="#time-error"></use></svg>
        <div class="body">
          <h2 class="title">
          [[trans('swBlockingError.weMissedYou')]]
          </h2>
          <h2>
          [[trans('swBlockingError.sessionTimeOut')]]
          </h2>
          <div>
          [[trans('swBlockingError.refreshInstruction')]]
          </div>
        </div>
      </dbq-modal>

      <dbq-modal id="offline" hidden>
        <svg><use href="#cloud-error"></use></svg>
        <div class="body">
          <h2 class="title">
          [[trans('swBlockingError.ohNo')]]
          </h2>
          <h2>
          [[trans('swBlockingError.noInternetConnection')]]
          </h2>
          <div>
          [[trans('swBlockingError.checkConnection')]]
          </div>
        </div>
      </dbq-modal>
    `;
  }

  static get properties() {
    return {
      sessionLength: {
        type: Number,
        reflectToAttribute: true,
        value: 1
      },
      sessionStart: {
        type: Number,
        value: 10
      },
      url: {
        type: String,
        value: './'
      },
      sessionTimer: {
        type: Object
      }
    };
  }

  ready() {
    super.ready();
    this.restartTimer();
    // this.checkOfflineStatus();
    window.addEventListener('offline', (e) => {
      this.checkOfflineStatus();
    });
    window.addEventListener('online', (e) => {
      this.checkOfflineStatus();
    });
    window.addEventListener('response', (resp) => {
      if (resp.detail.succeeded) {
        this.restartTimer();
      }
    });
  }

  restartTimer() {
    if (this.sessionTimer) {
      clearInterval(this.sessionTimer);
    }
    this.sessionStart = Date.now();

    this.sessionTimer = setInterval(() => {
      if (this.isSessionLengthInValid()) {
        this.showModal();
      }
    }, 1);
  }

  isSessionLengthInValid() {
    return Date.now() - this.sessionStart >= this.sessionLength * 1000;
  }

  showModal() {
    this.$.sessionEnded.open();
  }

  checkOfflineStatus() {
    if (navigator.onLine) {
      this.$.offline.close();
    } else {
      this.$.offline.open();
    }
  }

  startPolling() {
    this.$.poll.generateRequest().completes.then((req) => {
      if (req.succeeded) {
        this.$.sessionEnded.close();
        this.restartTimer();
      } else {
        setTimeout(() => {
          this.startPolling();
        }, 10000);
      }
    });
  }

  handleResponse(response) {
    // console.log('response', response);
  }

  _attachDom(dom) {
    this.appendChild(dom);
  }
}
customElements.define(SwBlockingError.is, SwBlockingError);
