import { PolymerElement } from '@polymer/polymer/polymer-element.js';
import { setPassiveTouchGestures } from '@polymer/polymer/lib/utils/settings.js';

let Polyglot = require('node-polyglot');

import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
// loads the Icon plugin
UIkit.use(Icons);


export class GlobalBase extends PolymerElement {
  static get properties() {
    return {
      page: String,
      asgn: String,
      unit: String,
      docsrc: String,
      token: String,
      studentId: String,
      isModal: Boolean,
      isInstructorFeedbackMode: {
        type: Boolean,
        value: false,
        notify: true
      },
      shouldDisable: {
        type: Boolean
      },
      isAssignmentAnnotations: {
        type: Boolean,
        value: false,
        notify: true
      },
      isPreview: {
        type: Boolean
      },
      prevSave: {
        type: Array,
        value: function () {
          return [];
        }
      }
    };
  }

  static get observers() {
    return ['shouldSetDisabled(isInstructorFeedbackMode, assignmentAnnotations)'];
  }

  constructor(){
    super();
    setPassiveTouchGestures(true);
    this.token = this.getTokenFromCookie('csrftoken')
  }

  getTokenFromCookie(name){
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
  }

  ready(){
    // setTimeout(() => {
    super.ready();
    this.initPolyGlot();

  }

  initPolyGlot(){
    if ( typeof(availableTranslations) !== 'undefined' && window.polyglot === undefined) {
      console.log("🚀 ~ file: global-base.js ~ line 77 ~ GlobalBase ~ initPolyGlot ~ availableTranslations", availableTranslations)
      window.polyglot = new Polyglot({phrases: availableTranslations});
    }
  }

  trans(key){
    if ( window.polyglot !== undefined){
      return window.polyglot.t(key);
    }else{
      this.initPolyGlot();
      return this.trans(key);
    }
  }

  shouldSetDisabled(isInstructorFeedbackMode, assignmentAnnotations) {
    this.shouldDisable = isInstructorFeedbackMode || assignmentAnnotations;
  }

  compareObjects(o, p) {
    var i,
      keysO = Object.keys(o).sort(),
      keysP = Object.keys(p).sort();
    if (keysO.length !== keysP.length) return false; //not the same nr of keys
    if (keysO.join('') !== keysP.join('')) return false; //different keys
    for (i = 0; i < keysO.length; ++i) {
      if (o[keysO[i]] instanceof Array) {
        if (!(p[keysO[i]] instanceof Array)) return false;

        //if (compareObjects(o[keysO[i]], p[keysO[i]] === false) return false
        //would work, too, and perhaps is a better fit, still, this is easy, too
        if (p[keysO[i]].sort().join('') !== o[keysO[i]].sort().join('')) return false;
      } else if (o[keysO[i]] instanceof Date) {
        if (!(p[keysO[i]] instanceof Date)) return false;
        if ('' + o[keysO[i]] !== '' + p[keysO[i]]) return false;
      } else if (o[keysO[i]] instanceof Function) {
        if (!(p[keysO[i]] instanceof Function)) return false;
        //ignore functions, or check them regardless?
      } else if (o[keysO[i]] instanceof Object) {
        if (!(p[keysO[i]] instanceof Object)) return false;
        if (o[keysO[i]] === o) {
          //self reference?
          if (p[keysO[i]] !== p) return false;
        } else if (this.compareObjects(o[keysO[i]], p[keysO[i]]) === false) return false; //WARNING: does not deal with circular refs other than ^^
      }
      if (o[keysO[i]] !== p[keysO[i]])
        //change !== to != for loose comparison
        return false; //not the same value
    }
    return true;
  }

  crossFade(el) {
    let fadePromise = new Promise((resolve, reject) => {
      el.shouldFade = true;
      let afterFadeOut = (event) => {
        el.removeEventListener('transitionend', afterFadeOut);
        resolve(el);
      }
      el.addEventListener('transitionend', afterFadeOut);
    });
    return fadePromise;
  }

  getCookie(name) {
    var cookieValue = null;
    if (document.cookie && document.cookie !== '') {
      var cookies = document.cookie.split(';');
      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i].trim();
        // Does this cookie string begin with the name we want?
        if (cookie.substring(0, name.length + 1) === name + '=') {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  }

  getUuid() {
    var d = new Date().getTime();
    if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
      d += performance.now(); //use high-precision timer if available
    }
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
  }

  getEndpoint(endpoint) {
    return fetch(endpoint, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': this.token //this.getCookie('csrftoken')
      },
      credentials: 'same-origin'
    })
      .then(
        function (response) {
          // response.status     => number 100–599
          // response.statusText => String
          // response.headers    => Headers
          // response.url        => String
          return response.text();
        },
        function (error) {
          return error.message;
        }
      )
      .then(function parse(data) {
        return JSON.parse(data);
      });
  }

  getEndpointasHTML(endpoint) {
    return fetch(endpoint, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': this.token //this.getCookie('csrftoken')
      },
      credentials: 'same-origin'
    }).then(
      function (response) {
        return response.text();
      },
      function (error) {
        return error.message;
      }
    );
  }

  makeOrGetOffscreenEl() {
    let offScreenEl = document.querySelector('#offScreenEl');
    if (!offScreenEl) {
      offScreenEl = document.createElement('textarea');
      offScreenEl.id = 'offScreenEl';
      offScreenEl.style = 'position: fixed; top: 0; left: 1000px;';
      document.body.appendChild(offScreenEl);
    }
    offScreenEl = document.querySelector('#offScreenEl');
    offScreenEl.value = '';
    return offScreenEl;
  }

  postEndpoint(endpoint, body) {
    return fetch(endpoint, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': this.token //this.getCookie('csrftoken')
      },
      credentials: 'same-origin'
    }).then(
      function (response) {
        var returnVal;
        if (response.status !== 200) {
          returnVal = response;
        } else {
          returnVal = response.text();
        }
        window.dispatchEvent(
          new CustomEvent('response', {
            bubbles: true,
            composed: true,
            detail: {
              succeeded: true
            }
          })
        );
        return returnVal;
      },
      function (error) {
        return error.message;
      }
    )
    .then((data) => {
      return this.amIJson(data) ? JSON.parse(data) : data;
    });
  }

  amIJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  cancleEvent(event) {
    event.preventDefault();
  }

  preventScrolling() {
    document.body.classList.add('noscroll');
    document.ontouchmove = function (event) {
      event.preventDefault();
    };
    document.body.addEventListener('dragstart', this.cancleEvent);
    document.body.addEventListener('dragover', this.cancleEvent);
    document.body.addEventListener('dragleave', this.cancleEvent);
    document.body.addEventListener('dragenter', this.cancleEvent);
    document.body.addEventListener('dragexit', this.cancleEvent);
    // document.body.addEventListener('dragend', this.cancleEvent);
  }

  enableScrolling() {
    document.body.classList.remove('noscroll');
    document.ontouchmove = function (event) {
      return true;
    };
    document.body.removeEventListener('dragstart', this.cancleEvent);
    document.body.removeEventListener('dragover', this.cancleEvent);
    document.body.removeEventListener('dragleave', this.cancleEvent);
    document.body.removeEventListener('dragenter', this.cancleEvent);
    document.body.removeEventListener('dragexit', this.cancleEvent);
  }

  /**
   * @return {String} time hh:mm am/pm
   */
  _getTime() {
    var d = new Date(),
      hr = d.getHours(),
      min = d.getMinutes(),
      seconds = d.getSeconds(),
      now = '',
      ampm = hr < 12 ? ' AM' : ' PM';

    hr = hr > 12 ? hr - 12 : hr;
    if (min < 10) {
      min = '0' + min;
    }

    // now = hr + ':' + min + ':'+ seconds + ampm;
    // hiding seconds per request
    now = hr + ':' + min + ':' + seconds + ampm;
    return now;
  }
}
customElements.define('global-base', GlobalBase);
